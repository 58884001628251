import { useContext, useEffect, useState, useRef } from "react";
import { message } from "antd";
import {
  GanttComponent,
  Inject,
  Selection,
  Toolbar,
  DayMarkers,
  ColumnsDirective,
  ColumnDirective,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-gantt";
import type { TimelineSettingsModel } from "@syncfusion/ej2-react-gantt";
import type { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { GlobalContext } from "../../../lib/context";
import request from "../../../utils/request";
import dayjs from "dayjs";
import styles from "./styles.module.css";

const TaskTimelineTab = () => {
  const ganttRef = useRef<GanttComponent>(null);
  const { currentProject } = useContext(GlobalContext);
  const [tasks, setTasks] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const processData = (tasks: any[]) => {
    const taskMap: any = {};

    tasks.sort((a: any, b: any) => a.TaskIndex - b.TaskIndex);

    tasks.forEach((task: any) => {
      task.subtasks = [];
      taskMap[task.TaskID] = task;
    });

    const out: any[] = [];

    tasks.forEach((task) => {
      if (task.ParentTaskId === 0) {
        out.push(task);
      } else {
        const parentTask = taskMap[task.ParentTaskId];
        if (parentTask) {
          parentTask.subtasks.push(task);
        }
      }
    });

    return out;
  };

  const fetchTasks = async () => {
    if (!currentProject["project_id"]) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const _tasks: any = await request.post("tasks/get", {
        id: currentProject["project_id"],
      });
      setTasks(processData(_tasks));
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [
    currentProject["project_id"],
    currentProject["schedule_start_date"],
    currentProject["schedule_end_date"],
  ]);

  const taskFields = {
    id: "TaskID",
    name: "TaskName",
    startDate: "StartDate",
    endDate: "EndDate",
    duration: "Duration",
    progress: "Progress",
    dependency: "Predecessor",
    child: "subtasks",
  };

  const toolbar = [
    "ExpandAll",
    "CollapseAll",
    "ZoomIn",
    "ZoomOut",
    "ZoomToFit",
    "ExcelExport",
    "CsvExport",
    "PdfExport",
  ];

  const weekDate = (dateString: any) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { weekday: "short" };
    return date.toLocaleDateString("en-US", options);
  };
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const timelineTemplate = (props: any) => {
    if (props.tier == "topTier") {
      return (
        <div className={styles.header} title={props.date}>
          <p className={styles.heading}>{weekDate(props.date)}</p>
          <p className={styles.leading}>{formatDate(props.date)}</p>
        </div>
      );
    }
  };

  const timelineSettings: TimelineSettingsModel = {
    topTier: { unit: "Day" },
    timelineUnitSize: 200,
  };

  const progressTemplate = (props: any) => {
    return props.taskData.Progress + "%";
  };

  const onClickToolbar = (args: ClickEventArgs) => {
    if (args.item.id?.endsWith("_excelexport")) {
      if (ganttRef.current) {
        ganttRef.current.excelExport();
      }
    } else if (args.item.id?.endsWith("_csvexport")) {
      if (ganttRef.current) {
        ganttRef.current!.csvExport();
      }
    } else if (args.item.id?.endsWith("_pdfexport")) {
      if (ganttRef.current) {
        ganttRef.current!.pdfExport({
          fitToWidthSettings: { isFitToWidth: true },
        });
      }
    }
  };

  if (loading) {
    return (
      <div className="d-grid-center bg-white p-4 border h-full">Loading...</div>
    );
  }

  if (
    !dayjs(currentProject["schedule_start_date"], "YYYY-MM-DD").isValid() ||
    !dayjs(currentProject["schedule_end_date"], "YYYY-MM-DD").isValid()
  ) {
    return (
      <div className="bg-white p-4 border h-full">
        Please select&nbsp;
        <span className="text-info heading">Schedule Start Date</span> and&nbsp;
        <span className="text-info heading">Schedule End Date</span>
      </div>
    );
  }

  return (
    <GanttComponent
      ref={ganttRef}
      dataSource={tasks}
      includeWeekend={true}
      highlightWeekends={true}
      allowSelection={true}
      allowUnscheduledTasks={true}
      showColumnMenu={false}
      gridLines="Both"
      dateFormat="y-MM-dd"
      toolbar={toolbar}
      splitterSettings={{ position: "50%" }}
      toolbarClick={onClickToolbar}
      taskFields={taskFields}
      loadingIndicator={{ indicatorType: "Shimmer" }}
      height="100%"
      projectStartDate={dayjs(
        currentProject["schedule_start_date"],
        "YYYY-MM-DD"
      )
        .subtract(1, "day")
        .format("YYYY-MM-DD")}
      projectEndDate={dayjs(currentProject["schedule_end_date"], "YYYY-MM-DD")
        .add(1, "day")
        .format("YYYY-MM-DD")}
      timelineSettings={timelineSettings}
      timelineTemplate={timelineTemplate}
      treeColumnIndex={1}
      allowExcelExport={true}
      allowPdfExport={true}
    >
      <ColumnsDirective>
        <ColumnDirective field="TaskID" visible={false}></ColumnDirective>
        <ColumnDirective field="TaskName" width={300}></ColumnDirective>
        <ColumnDirective field="StartDate" textAlign="Right"></ColumnDirective>
        <ColumnDirective field="EndDate" textAlign="Right"></ColumnDirective>
        <ColumnDirective field="Duration" textAlign="Right"></ColumnDirective>
        <ColumnDirective
          field="Progress"
          textAlign="Right"
          template={progressTemplate}
        ></ColumnDirective>
      </ColumnsDirective>
      <Inject
        services={[Selection, DayMarkers, Toolbar, ExcelExport, PdfExport]}
      />
    </GanttComponent>
  );
};

export { TaskTimelineTab };
